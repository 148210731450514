import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { DiscussionEmbed } from "disqus-react"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

const disqusConfig = {
  shortname: `https-blog-andreyosipenko-com`,
  config: { identifier: post.frontmatter.title },
}

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <div style={{
            display: 'inline-block',
            height: '20px',
            width: '100%',
          }}>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                float: 'left',
              }}
            >
              {post.frontmatter.date} •  Время на прочтение: {post.frontmatter.timeToRead}
            </p>

          </div>
          <h1
            style={{
              marginTop: 0,
              marginBottom: 10,
              display: 'inline',
            }}
          >
            {post.frontmatter.title}
          </h1>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} style={{display:'block'}}/>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <DiscussionEmbed {...disqusConfig} />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        timeToRead
      }
    }
  }
`
